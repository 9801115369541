import styled from "@emotion/styled";

import { ContentNewsletterWidget } from "scmp-app/components/content/content-newsletter-widget";

export const StyledContentNewsletterWidget = styled(ContentNewsletterWidget)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  border-block-start: 1px solid #d8d8d8;

  padding-block: 16px;
`;
