import styled from "@emotion/styled";

type Props = {
  $hasMedia: boolean;
};

export const TweetContainer = styled.div<Props>`
  inline-size: 100%;

  > div > div {
    display: flex;
    justify-content: center;
  }
`;
