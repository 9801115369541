import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

const Component: FunctionComponent<ContentSchemaRenderProps> = ({ schemaNode }) => (
  <>{schemaNode?.data}</>
);

Component.displayName = "Text";
export const Text = styled(Component)``;
