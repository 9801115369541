import camelcaseKeys from "camelcase-keys";
import parse from "style-to-object";

enum AttributeEnum {
  Style = "style",
}

// Attributes from backend is using class rather than className
export function normalizeJsxAttribute(attributes?: Record<string, unknown>) {
  function replaceToJsxAttribute(key: string) {
    return key.replace(/^class$/, "className");
  }

  // Style value sample: border-radius:12px
  function massageStyleAttribute(value: string) {
    const styleObject = parse(value);
    // Suppress react warning by camelCasing the css string
    if (styleObject) return camelcaseKeys(styleObject);
    return null;
  }

  if (!attributes) return attributes;
  return Object.keys(attributes).reduce(
    (accumulator, key) => {
      const value = attributes[key];
      if ((key as AttributeEnum) === AttributeEnum.Style) {
        accumulator[replaceToJsxAttribute(key)] = massageStyleAttribute(value as string);
      } else {
        accumulator[replaceToJsxAttribute(key)] = value;
      }
      return accumulator;
    },
    {} as Record<string, unknown>,
  );
}
