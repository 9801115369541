import { theme, useResponsive } from "@product/scmp-sdk";
import classNames from "classnames";
import type { FunctionComponent, ReactElement, ReactNode } from "react";
import { Children, cloneElement, isValidElement } from "react";

import { SpeechSkipClassName } from "scmp-app/components/article/article-speech/consts";

export type Props = {
  breakpointString?: string;
  children?: ReactNode;
};

export const ArticleSpeechSkipContainer: FunctionComponent<Props> = ({
  breakpointString = theme.breakpoints.up("mobile"),
  children,
}) => {
  const isInBreakpoint = useResponsive(breakpointString);
  if (!children) return null;

  return (
    <>
      {Children.map(children, child => {
        if (isValidElement(child)) {
          const parsedChild = child as ReactElement<{ className: string }>;
          return cloneElement(parsedChild, {
            className: classNames(
              isInBreakpoint && SpeechSkipClassName,
              parsedChild.props.className,
            ),
          });
        }
        return child;
      })}
    </>
  );
};

ArticleSpeechSkipContainer.displayName = "ArticleSpeechSkipContainer";
