export enum VideoSource {
  SCMP = "SCMP",
}

// cspell: ignore datawrapper dwcdn
export enum StaticSourceUrl {
  DataWrapper = "datawrapper.dwcdn.net",
  FacebookSocialPlugin = "www.facebook.com/plugins/post.php",
  SCMPMultimedia = "multimedia.scmp.com",
}
