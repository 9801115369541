export enum ImageSizeStyle {
  "118x118" = "118x118",
  "237x147" = "237x147",
  "768x768" = "768x768",
}

export const useImageUtils = () => {
  const getResizedImageUrl = (imageUrl: string, width: number) => {
    const regPattern = /\/\/cdn[1-4]*\.i-scmp\.com\//;
    const regexHasCdnDomain = new RegExp(regPattern);
    if (regexHasCdnDomain.test(imageUrl)) {
      const replacedImageUrlDomain = imageUrl.replace(regPattern, "//img.i-scmp.com/");
      return replacedImageUrlDomain.replaceAll(
        "/sites/default/files/",
        `\/cdn-cgi\/image\/fit=contain,width=${width},format=auto\/sites\/default\/files\/`,
      );
    }
    return imageUrl;
  };

  return {
    getResizedImageUrl,
  };
};
