import styled from "@emotion/styled";
import type { NewsletterWidgetProps } from "@product/scmp-sdk";
import { NewsletterVariant } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import type { inlineWidgetContent$key } from "scmp-app/queries/__generated__/inlineWidgetContent.graphql";

import { Container, StyledContentNewsletterWidget } from "./styles";

export type ExtraProps = {
  newsletterVariant?: NewsletterWidgetProps["variant"];
};

type Props = {
  reference?: inlineWidgetContent$key;
} & ContentSchemaRenderProps &
  ExtraProps;

const Component: FunctionComponent<Props> = ({
  newsletterVariant = NewsletterVariant.Article,
  reference: reference_,
}) => {
  const content = useFragment(
    graphql`
      fragment inlineWidgetContent on Content {
        ...contentNewsletterWidgetContent
      }
    `,
    reference_ ?? null,
  );
  if (!content) return null;

  return (
    <ArticleSpeechSkipContainer>
      <Container>
        <StyledContentNewsletterWidget reference={content} variant={newsletterVariant} />
      </Container>
    </ArticleSpeechSkipContainer>
  );
};

Component.displayName = "InlineWidget";
export const InlineWidget = styled(Component)``;
