import styled from "@emotion/styled";

export const Heading1 = styled.h1`
  display: inherit;

  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const Heading2 = styled.h2`
  display: inherit;

  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const Heading3 = styled.h3`
  display: inherit;

  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const Heading4 = styled.h4`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;
