import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";

import PhotoGalleryIcon from "./icon-photo-gallery.svg";

export const ImageGrid = styled.div`
  position: relative;

  display: grid;
  grid:
    "main main main" min-content
    / 1fr 1fr 1fr;
  gap: 8px;

  inline-size: 100%;
`;

export const MainCell = styled.div`
  grid-area: main;

  inline-size: 100%;
  block-size: auto;

  aspect-ratio: 3 / 2;
`;

export const BottomCell = styled.div`
  grid-row-start: main-end;

  inline-size: 100%;
  block-size: auto;

  aspect-ratio: 3 / 2;
`;

type ImageProps = {
  $artDirection: "landscape" | "portrait";
};

export const Image = styled(BaseImage, { ...transientOptions })<ImageProps>`
  inline-size: 100%;
  block-size: 100%;

  cursor: pointer;

  object-fit: cover;
  object-position: ${props => (props.$artDirection === "portrait" ? "top" : "center")};
`;

export const Indicator = styled.div`
  grid-area: main;

  display: flex;
  flex-flow: column nowrap;
  place-self: start end;
  justify-content: center;
  align-items: center;

  inline-size: 60px;
  block-size: 60px;
  margin: 16px;

  border-radius: 50%;

  background: #141518;

  cursor: pointer;

  opacity: 0.9;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 80px;
    block-size: 80px;
    margin: 24px;
  }
`;

export const IndicatorIcon = styled(PhotoGalleryIcon)`
  inline-size: 16px;
  block-size: 16px;
  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 20px;
    block-size: 20px;
  }
`;

export const IndicatorValue = styled.div`
  margin-block-start: 6px;

  color: #ffffff;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 14px;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 18px;
    line-height: 18px;
  }
`;
