import styled from "@emotion/styled";
import { PageType, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import classNames from "classnames";
import { type FunctionComponent } from "react";

import type { Props as BaseLinkProps } from "scmp-app/components/common/base-link";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { data as appData, config, tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { isAbsoluteUrl } from "scmp-app/lib/utils";
import type { contentSchemaRenderContent$data } from "scmp-app/queries/__generated__/contentSchemaRenderContent.graphql";

import { AnchorRelationship, AnchorTarget } from "./enums";
import { checkIsInternalScmpLink } from "./helpers";
import { StyledBaseLink, Text } from "./styles";

type GetQueryParametersModuleParameters = {
  isDrupalAutoLink?: boolean;
  pageType: PageType;
};

const getQueryParametersModule = (parameters: GetQueryParametersModuleParameters) => {
  const { isDrupalAutoLink, pageType } = parameters;

  if (isDrupalAutoLink) {
    return tracking.module.InlineAuto;
  }

  return pageType === PageType.PostiesArticle
    ? tracking.module.InlinePosties
    : tracking.module.Inline;
};

export type Props = {
  className?: string[];
  href?: string;
  id?: string;
  name?: string;
  reference?: contentSchemaRenderContent$data;
} & ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({
  children,
  className,
  href = "",
  id,
  name,
  parentSchemaNode,
  ...attribs
}) => {
  const isInternalScmpLink = checkIsInternalScmpLink(href);
  // Link added in drupal by system config automatically
  const isDrupalAutoLink = className?.includes("d8-auto-link");
  const isDigitalArchiveLink =
    !isDrupalAutoLink &&
    ["h3", "p"].includes(parentSchemaNode?.type ?? "") &&
    parentSchemaNode?.children?.length === 1 &&
    parentSchemaNode?.children?.[0].type === "a" &&
    isInternalScmpLink;

  const currentPageType = useCurrentPageType();
  // Generate link with custom parameters
  const queryParameters: BaseLinkProps["query"] = {
    module: getQueryParametersModule({
      isDrupalAutoLink,
      pageType: currentPageType,
    }),
    pgtype: currentPageType,
  };

  const isInternalUrl =
    isInternalScmpLink ||
    [
      appData.application.scmp.domain,
      appData.application.goldthread.domain,
      config.general.siteUrl,
    ].some(internalUrl => href.includes(internalUrl));
  let anchorTarget: OrUndefined<AnchorTarget>;
  let anchorRelationship: OrUndefined<string>;

  if (!isAbsoluteUrl(href) || !isInternalUrl || className?.includes("js-tracking-referral")) {
    if (!isInternalUrl) anchorRelationship = AnchorRelationship.NoFollow;
    if (!href.startsWith("#")) anchorTarget = AnchorTarget.Blank;
  }

  const handleGATracking = () => {
    const result = /\/(?<articleId>\d+)\//.exec(href);
    const articleId =
      isInternalScmpLink && result?.groups?.articleId ? result.groups.articleId : null;

    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        destination_url: href,
        ...(articleId ? { article_id: articleId } : {}),
        page_type: "article",
        widget_name: "inline_link",
      },
      subcategory: "widget",
    });
  };

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      options: {
        isSendGA4Tracking: true,
        shouldSendOnce: true,
      },
    });

  if (isDigitalArchiveLink) return null;

  return (
    <StyledBaseLink
      anchorProps={{ id, name, rel: anchorRelationship, target: anchorTarget }}
      className={classNames(className)}
      onClick={handleGATracking}
      pathname={href}
      query={isInternalUrl ? queryParameters : {}}
      ref={captureTrackImpressionEventTargetElement}
      {...attribs}
    >
      <Text>{children}</Text>
    </StyledBaseLink>
  );
};

Component.displayName = "Link";
export const Link = styled(Component)``;
