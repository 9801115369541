import styled from "@emotion/styled";
import { notEmpty } from "@product/scmp-sdk";
import { useResizeObserver } from "@react-hookz/web";
import { UnsupportedBehavior } from "@shopify/react-idle";
import { type FunctionComponent, useRef, useState } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import { normalizeJsxAttribute } from "scmp-app/lib/utils";

import { Container, StyledIframe } from "./styles";

type Props = {
  frameborder?: string;
  height?: string;
  id?: string;
  name?: string;
  scrolling?: string;
  src?: string;
  style?: string;
  title?: string;
  width?: string;
} & ContentSchemaRenderProps;

const defaultSize = "600";

export const Component: FunctionComponent<Props> = ({
  schemaNode,
  src: source = "",
  ...attribs
}) => {
  const element = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState(0);
  const {
    height = defaultSize,
    width = defaultSize,
    ...schemaAttributes
  } = schemaNode.attribs ?? {};

  const justifyWidth = (value: string) => {
    if (value.endsWith("%")) return defaultSize;
    if (value === "auto") return defaultSize;
    return width;
  };

  const parsedWidth = Number.parseFloat(justifyWidth(width));
  const parsedHeight = Number.parseFloat(height);
  const scaleFactor = clientWidth > 0 && parsedWidth > 0 ? clientWidth / parsedWidth : 1;
  const aspectRatio = parsedWidth > 0 && parsedHeight > 0 ? parsedWidth / parsedHeight : 1;

  useResizeObserver(element, ({ contentRect: { width } }) => {
    setClientWidth(width);
  });

  if (!notEmpty(source)) return null;

  return (
    <Container $aspectRatio={aspectRatio} ref={element}>
      <IdleQueue unsupportedBehavior={UnsupportedBehavior.AnimationFrame}>
        <StyledIframe
          $scaleFactor={scaleFactor}
          src={source}
          {...normalizeJsxAttribute(schemaAttributes)}
          {...attribs}
          height={parsedHeight}
          style={{}}
          width={parsedWidth}
        />
      </IdleQueue>
    </Container>
  );
};

Component.displayName = "DataWrapperIframe";

export const DataWrapperIframe = styled(Component)``;
