import styled from "@emotion/styled";

export const StyledParagraph = styled.p`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  overflow-wrap: break-word;
`;

export const StyledDiv = styled.div``;
