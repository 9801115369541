/**
 * @generated SignedSource<<51cd6e5cb076d9ac329f0fc19edb9b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSchemaRenderContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"blockquoteQuoteContent" | "imageContent" | "inlineWidgetContent" | "photoGalleryWidgetContent" | "scmpYoutubeContent">;
  readonly " $fragmentType": "contentSchemaRenderContent";
};
export type contentSchemaRenderContent$key = {
  readonly " $data"?: contentSchemaRenderContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSchemaRenderContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "blockquoteQuoteContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "imageContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "inlineWidgetContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "photoGalleryWidgetContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "scmpYoutubeContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "e59088f22bf4438d8c71943be0e2479b";

export default node;
