import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type {
  ContentSchemaRenderProps,
  RenderComponent,
} from "scmp-app/components/schema-render/content";
import type { blockquoteQuoteContent$key } from "scmp-app/queries/__generated__/blockquoteQuoteContent.graphql";

import {
  StyledBlockquote,
  StyledFigcaption,
  StyledFigcaptionContainer,
  StyledFigcaptionIcon,
  StyledFigure,
  StyledQuoteIcon,
} from "./styles";

type Props = {
  reference?: blockquoteQuoteContent$key;
} & ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({ reference: reference_, schemaNode }) => {
  const content = useFragment(
    graphql`
      fragment blockquoteQuoteContent on Content {
        ...contentShareWidgetContent
      }
    `,
    reference_ ?? null,
  );

  const [quoteNode, sourceNode] = schemaNode.children ?? [];
  if (!content) return null;

  return (
    <StyledFigure>
      <StyledQuoteIcon />
      <StyledBlockquote>{quoteNode?.data}</StyledBlockquote>
      <StyledFigcaptionContainer>
        <StyledFigcaptionIcon />
        <StyledFigcaption>{sourceNode?.data}</StyledFigcaption>
      </StyledFigcaptionContainer>
    </StyledFigure>
  );
};

Component.displayName = "BlockquoteQuote";

export const BlockquoteQuote: RenderComponent<Props> = styled(Component)``;
BlockquoteQuote.isHandlingOwnChildren = true;
