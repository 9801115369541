/**
 * @generated SignedSource<<6d2b3deb2087730f7390c7de1499c7b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inlineWidgetContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"contentNewsletterWidgetContent">;
  readonly " $fragmentType": "inlineWidgetContent";
};
export type inlineWidgetContent$key = {
  readonly " $data"?: inlineWidgetContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"inlineWidgetContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "inlineWidgetContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentNewsletterWidgetContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "b09b2a9962c2d9b907fefb79ea8695a7";

export default node;
