import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";

export const useTracking = () => {
  const trackClick = (index: string, totalPhoto: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "article_gallery",
      },
      subcategory: "widget",
    });

    if (index === "1") {
      sendGA4Tracking({
        action: "imp",
        category: "recirculation",
        customized_parameters: {
          page_type: "article",
          photo_num: index,
          total_photo: totalPhoto,
          widget_name: "article_gallery",
        },
        subcategory: "widget",
      });
    }
  };

  return {
    trackClick,
  };
};
