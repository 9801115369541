/**
 * @generated SignedSource<<06f9d951fce1b853a5ddebac9584810d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentNewsletterWidgetContent$data = {
  readonly relatedNewsletters?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
  } | null | undefined> | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly relatedNewsletters: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly relatedNewsletters: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "contentNewsletterWidgetContent";
};
export type contentNewsletterWidgetContent$key = {
  readonly " $data"?: contentNewsletterWidgetContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentNewsletterWidgetContent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Newsletter",
    "kind": "LinkedField",
    "name": "relatedNewsletters",
    "plural": true,
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "helpersMassageToNewsletterItemNewsletter",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entityId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "alternativeName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FormattedValue",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FormattedValue",
            "kind": "LinkedField",
            "name": "summary",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FormattedValue",
            "kind": "LinkedField",
            "name": "homepageDescription",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          }
        ],
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentNewsletterWidgetContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Article",
      "abstractKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "f0779176fef1bde55ee506cfa0a98b5a";

export default node;
