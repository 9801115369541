import styled from "@emotion/styled";

import { SCMPYoutubeVideo } from "scmp-app/components/scmp-youtube-video";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const StyledSCMPYoutubeVideo = styled(SCMPYoutubeVideo)`
  ${props => props.theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
  }
`;
