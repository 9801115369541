import styled from "@emotion/styled";

import { shouldForwardProp } from "scmp-app/components/schema-render/content/styles";

export const StyledDiv = styled("div", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  word-break: break-word;

  & .fb-post {
    text-align: center;
    blockquote {
      text-align: start;
    }
  }
`;
