/**
 * @generated SignedSource<<511782d3f8e89c13738b055908408129>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentShareWidgetLiveContent$data = {
  readonly body: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly entityId: string;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "contentShareWidgetLiveContent";
};
export type contentShareWidgetLiveContent$key = {
  readonly " $data"?: contentShareWidgetLiveContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentShareWidgetLiveContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentShareWidgetLiveContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveContent",
  "abstractKey": null
};

(node as any).hash = "7192039c5d2d8ecaac1079fec75c5e75";

export default node;
