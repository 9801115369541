/**
 * @generated SignedSource<<6e26cd9ab3e8642bded7c9976e3e660d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type imageContent$data = {
  readonly headline: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"checkersCheckContentContainsStarlingImageContent">;
  readonly " $fragmentType": "imageContent";
};
export type imageContent$key = {
  readonly " $data"?: imageContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"imageContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "imageContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "checkersCheckContentContainsStarlingImageContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "02ad6f4bf757b7a51c5b34aa1ab324a4";

export default node;
