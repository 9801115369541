import styled from "@emotion/styled";

type ContainerProps = {
  $aspectRatio: number;
};

export const Container = styled.div<ContainerProps>`
  aspect-ratio: ${props => props.$aspectRatio};

  position: relative;
`;

type IframeProps = {
  $scaleFactor: number;
};

export const StyledIframe = styled.iframe<IframeProps>`
  position: absolute;

  transform: scale(${props => props.$scaleFactor});
  transform-origin: top left;
`;
