import styled from "@emotion/styled";
import { notEmpty } from "@product/scmp-sdk";
import { UnsupportedBehavior } from "@shopify/react-idle";
import toNumber from "lodash/toNumber";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { StaticSourceUrl } from "scmp-app/components/schema-render/common/iframe/enums";
import { getDimensionWithUnit } from "scmp-app/components/schema-render/common/iframe/helpers";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import { StyledSCMPYouTubeVideoFooter } from "scmp-app/components/scmp-youtube-video/styles";
import { normalizeJsxAttribute } from "scmp-app/lib/utils";

import { IframeAllowValue, VideoSourceUrl } from "./enums";
import type { StyledProps } from "./styles";
import { Container, StyledIframe } from "./styles";

export type Props = {
  allow?: string;
  allowfullscreen?: string;
  frameborder?: string;
  height?: string;
  src?: string;
  style?: string;
  title?: string;
  width?: string;
} & ContentSchemaRenderProps &
  ResponsiveAttributes;

type ResponsiveAttributes = {
  ["data-mdheight"]?: string;
  ["data-responsive"]?: string;
  ["data-smheight"]?: string;
};

export const Component: FunctionComponent<Props> = ({
  allow,
  allowfullscreen,
  frameborder,
  height,
  schemaNode,
  src: source = "",
  style: _style,
  title,
  width,
  ...attribs
}) => {
  const iframeAllowFullScreen = allowfullscreen ? true : false;
  const iframeAllow =
    iframeAllowFullScreen && !notEmpty(allow) ? IframeAllowValue.Fullscreen : allow;

  const variant = useMemo<StyledProps["$variant"]>(() => {
    const parsedWidth = toNumber(width);
    const parsedHeight = toNumber(height);

    if (
      [StaticSourceUrl.FacebookSocialPlugin, StaticSourceUrl.SCMPMultimedia].some(url =>
        source.includes(url),
      )
    ) {
      return {
        $blockSize: getDimensionWithUnit(height),
        $type: "static",
      };
    }

    const isVideoSource = Object.values(VideoSourceUrl).some(url => source.includes(url));
    if (isVideoSource)
      return {
        $aspectRatio: parsedWidth && parsedHeight ? parsedWidth / parsedHeight : 16 / 9,
        $type: "video",
      };

    const isResponsive = attribs["data-responsive"] === "1";
    if (isResponsive) {
      return {
        $responsiveBlockSize: {
          desktopUp: getDimensionWithUnit(height),
          mobileUp: getDimensionWithUnit(
            attribs["data-smheight"] ?? attribs["data-mdheight"] ?? height,
          ),
          tabletUp: getDimensionWithUnit(attribs["data-mdheight"] ?? height),
        },
        $type: "responsive",
      };
    }

    if (!notEmpty(width) || !notEmpty(height))
      return { $aspectRatio: 16 / 9, $type: "aspectRatio" };

    // Prevent the case on width or height is 100%
    if (parsedWidth > 0 && parsedHeight > 0)
      return { $aspectRatio: parsedWidth / parsedHeight, $type: "aspectRatio" };

    return { $blockSize: getDimensionWithUnit(height), $type: "static" };
  }, [attribs, height, source, width]);

  // Remove style from iframe to prevent not able to show infographic widget
  const { style: _, ...schemaAttributes } = schemaNode.attribs ?? {};

  return (
    <>
      <Container $variant={variant}>
        <IdleQueue unsupportedBehavior={UnsupportedBehavior.AnimationFrame}>
          <StyledIframe
            $variant={variant}
            allow={iframeAllow}
            allowFullScreen={iframeAllowFullScreen}
            frameBorder={frameborder}
            src={source}
            {...normalizeJsxAttribute(schemaAttributes)}
            {...attribs}
          />
        </IdleQueue>
      </Container>
      {title && <StyledSCMPYouTubeVideoFooter title={title} variant="default" />}
    </>
  );
};

Component.displayName = "GenericIframe";
export const GenericIframe = styled(Component)``;
