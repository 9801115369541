import { notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";

import { query } from "scmp-app/components/schema-render/common/div/photo-gallery";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { photoGalleryQuery } from "scmp-app/queries/__generated__/photoGalleryQuery.graphql";
import type { photoGalleryWidgetContent$key } from "scmp-app/queries/__generated__/photoGalleryWidgetContent.graphql";
import type { photoGalleryWidgetGallery$key } from "scmp-app/queries/__generated__/photoGalleryWidgetGallery.graphql";

import { DynamicPhotoGalleryDialog } from "./dynamics";
import { useTracking } from "./hooks";
import {
  BottomCell,
  Image,
  ImageGrid,
  Indicator,
  IndicatorIcon,
  IndicatorValue,
  MainCell,
} from "./styles";

type ComponentProps = {
  className?: string;
  photoGalleryQueryReference: PreloadedQuery<photoGalleryQuery>;
  reference: photoGalleryWidgetContent$key;
};

export const PhotoGalleryWidget: FunctionComponent<ComponentProps> = ({
  className,
  photoGalleryQueryReference,
  reference: reference_,
}) => {
  const { trackClick } = useTracking();

  const content = useFragment<photoGalleryWidgetContent$key>(
    graphql`
      fragment photoGalleryWidgetContent on Content {
        ...photoGalleryDialogContent
      }
    `,
    reference_,
  );

  const gallery_ = usePreloadedQuery(query, photoGalleryQueryReference);
  const gallery = useFragment<photoGalleryWidgetGallery$key>(
    graphql`
      fragment photoGalleryWidgetGallery on Gallery {
        images {
          ...photoGalleryDialogImage
          width
          height
          title
          url
          resized: style(filter: { style: "portrait_mq" }) {
            url
          }
        }
      }
    `,
    gallery_.content,
  );

  const filteredImages = gallery?.images?.filter(notEmpty) ?? [];
  const numberOfPhoto = filteredImages.length.toString();

  const mainImage = first(filteredImages);
  const bottomImages = filteredImages.slice(1, 4);

  const computeArtDirection = (image: (typeof filteredImages)[number]) => {
    if (!image.height || !image.width) return "portrait";
    return image.height / image.width >= 1 ? "portrait" : "landscape";
  };

  const [openedItemIndex, setOpenedItemIndex] = useState<number>();

  const handleOpenImageGallery = (index: number) => {
    setOpenedItemIndex(index);
    trackClick(`${index + 1}`, numberOfPhoto);
  };

  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "article_gallery",
      },
      subcategory: "widget",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: {
        intersectionThreshold: [0.1],
        isSendGA4Tracking: true,
        shouldSendOnce: true,
        shouldThrottle: true,
      },
    });

  if (!mainImage) return null;

  return (
    <ImageGrid className={className} ref={captureTrackImpressionEventTargetElement}>
      <MainCell>
        <Image
          $artDirection={computeArtDirection(mainImage)}
          $aspectRatio="3 / 2"
          $objectFit="cover"
          alt={mainImage.title ?? undefined}
          onClick={() => handleOpenImageGallery(0)}
          src={mainImage.resized?.url ?? mainImage.url}
        />
      </MainCell>
      {bottomImages.length > 0 &&
        bottomImages.map((image, index) => (
          <BottomCell key={index}>
            <Image
              $artDirection={computeArtDirection(image)}
              $aspectRatio="3 / 2"
              $objectFit="cover"
              alt={image.title ?? undefined}
              onClick={() => handleOpenImageGallery(index + 1)}
              src={image.resized?.url ?? image.url}
            />
          </BottomCell>
        ))}
      {filteredImages.length > 4 && (
        <Indicator onClick={() => handleOpenImageGallery(0)}>
          <IndicatorIcon />
          <IndicatorValue>{filteredImages.length}</IndicatorValue>
        </Indicator>
      )}
      {openedItemIndex !== undefined && (
        <DynamicPhotoGalleryDialog
          images={filteredImages}
          initialIndex={openedItemIndex}
          onClose={() => setOpenedItemIndex(undefined)}
          reference={content}
        />
      )}
    </ImageGrid>
  );
};

PhotoGalleryWidget.displayName = "PhotoGalleryWidget";
