import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { shouldForwardProp } from "scmp-app/components/schema-render/content/styles";

export const Caption = styled("div", { shouldForwardProp })`
  margin-block-end: 20px;

  color: #666666;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16.4px;
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-end: 32px;

    font-size: 16px;
    line-height: 19px;
  }
`;

export const StyledContainer = styled.div`
  margin-block-end: 42px;
`;

export const TableContainer = styled.div`
  inline-size: 100%;

  overflow-x: auto;
`;

export const TableFooter = styled.div`
  display: none;
  justify-content: flex-end;

  padding-block-start: 11px;

  border-block-start: 1px solid #000000;
`;

export const TableFooterBrand = styled.div`
  color: #666666;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
`;

export const StyledTable = styled("table", { shouldForwardProp })`
  inline-size: 100%;
`;

export const StyledSummary = styled("div", { shouldForwardProp })`
  margin-block-end: 20px;

  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  line-height: 24px;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 22px;
  }
`;
