import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { ContentShareWidget } from "scmp-app/components/content/content-share-widget";

import QuoteIcon from "./icon-quote.svg";

export const StyledQuoteIcon = styled(QuoteIcon)``;

export const StyledFigure = styled.figure`
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;

  margin-block: 20px;
  padding-block: 12px;
  padding-inline: 12px 40px;

  font-weight: 900;
  font-size: 24px;
  font-family: ${fontMerriweather};
  line-height: 31px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 16px;
    padding-inline: 20px 120px;
  }
`;

export const StyledBlockquote = styled.blockquote`
  margin: 0;

  color: #000000;
  font-weight: 300;
  font-size: 20px;
  font-family: ${fontMerriweather};
  line-height: 26px;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 22px;
    line-height: 28.6px;
  }
`;

export const StyledFigcaptionContainer = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const StyledFigcaptionIcon = styled.div`
  border-block-start: 1px solid #333333;

  inline-size: 12px;
  margin-block: 10px;
`;

export const StyledFigcaption = styled.figcaption`
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontMerriweather};
  line-height: 20px;

  opacity: 0.7;
`;

export const StyledFooter = styled.footer`
  margin-block-start: 12px;

  border-block-start: 4px solid #001246;
`;

export const StyledContentShareWidget = styled(ContentShareWidget)`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
`;
