/**
 * @generated SignedSource<<55f6158b5a5cac127536396753aad050>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type photoGalleryWidgetContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"photoGalleryDialogContent">;
  readonly " $fragmentType": "photoGalleryWidgetContent";
};
export type photoGalleryWidgetContent$key = {
  readonly " $data"?: photoGalleryWidgetContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"photoGalleryWidgetContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "photoGalleryWidgetContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "photoGalleryDialogContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "e600320634630721f58a13644f0091f4";

export default node;
